import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sub-tab-body pt-3" }
const _hoisted_2 = { class: "flex flex-row w-full mb-4 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectButton = _resolveComponent("SelectButton")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_tab = _resolveComponent("tab")!
  const _component_TabRow = _resolveComponent("TabRow")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectButton, {
      modelValue: $setup.templateLocale,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.templateLocale) = $event)),
      options: $setup.languages,
      unselectable: false
    }, null, 8, ["modelValue", "options"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TabRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_Tab, {
            to: { name: 'SettingReportBody' },
            label: "Body"
          }),
          _createVNode(_component_tab, {
            to: { name: 'SettingReportHeader' },
            label: "Header"
          }),
          _createVNode(_component_tab, {
            to: { name: 'SettingReportContactInfo' },
            label: "Contact info"
          }),
          _createVNode(_component_tab, {
            to: { name: 'SettingReportMedicalInfo' },
            label: "Patient info"
          }),
          _createVNode(_component_tab, {
            to: { name: 'SettingReportFooter' },
            label: "Footer"
          }),
          _createVNode(_component_tab, {
            to: { name: 'SettingReportSignature' },
            label: "Signature"
          }),
          _createVNode(_component_tab, {
            to: { name: 'SettingReportsClosing' },
            label: "Closing"
          }),
          _createVNode(_component_tab, {
            to: { name: 'SettingReportTerms' },
            label: "Terms"
          }),
          _createVNode(_component_tab, {
            to: { name: 'SettingReportBackground' },
            label: "Background"
          })
        ]),
        _: 1
      })
    ]),
    ($setup.templateLocale)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 0,
          language: $setup.templateLocale,
          organization: $setup.organizationId
        }, null, 8, ["language", "organization"]))
      : _createCommentVNode("", true)
  ]))
}