
import TabRow from "@/components/navigation/TabRow.vue"
import Tab from "@/components/navigation/Tab.vue"
import { computed, ref, watch } from "vue"
import { useRoute } from "vue-router"

export default {
  name: "ReportTemplatesTabs",
  components: { Tab, TabRow },
  setup() {
    const templateLocale = ref("EN")
    const languages = ["EN", "NL", "FR", "DE"]
    const route = useRoute()
    const organizationId = computed(() => route.params.organization_id as string)

    return {
      templateLocale,
      languages,
      organizationId,
      route,
    }
  },
}
