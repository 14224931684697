<template>
  <router-link
    v-ripple
    class="tab p-ripple"
    :to="to"
  >
    {{ label }}
  </router-link>
</template>

<script>
export default {
  name: "Tab",
  props: {
    label: String,
    to: Object,
    replace: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
a.tab {
  color: rgba(0, 0, 0, 0.6);
  background: #ffffff;
  cursor: pointer;
  padding: 15px 15px 7px 15px;
  overflow: hidden;
  font-size: 20px;
  text-decoration: none;
  align-items: center;
  transition: none;
  border-bottom: 2px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  flex: 0 0 auto;
  flex-wrap: nowrap;
}
a.tab:hover {
}
a.tab.router-link-active {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}
</style>
