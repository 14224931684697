<template>
  <div class="w-full overflow-x-auto overflow-y-hidden">
    <div class="tabs">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "TabRow",
}
</script>

<style scoped>
.tabs {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}
</style>
